import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from './Navbar';
import SideMenu from './SideMenu';
import axios from 'axios';

 const UserReports = () => {
 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);
 const [disable, setDisable]= useState(true);
 const [todate, setTodate]= useState([]);
 const [fromdate, setFromdate]= useState([]);
 const [todateformat, setTodateformat]= useState('');
 const [fromdateformat, setFromdateformat]= useState('');
 const [state, setState] = useState({list:[], language: ''});

 const data1 = localStorage.getItem("response");
 const parsedData = JSON.parse(data1); 
 var userdata = parsedData.data.data.token;

 const handletodate= (e)=>{
    const gettodatevalue= e.target.value;
    const setdateformat= gettodatevalue.split('-');
    const settoyear= setdateformat[0];
    const settomonth= setdateformat[1];
    const settodate= setdateformat[2];
    const settodateformat= settoyear+"-"+settomonth+"-"+settodate;
    setTodate(gettodatevalue);
    setTodateformat(settodateformat);
    setDisable(false);
    //console.log(settodateformat);
 }
 const handlefromdate= (e)=>{
    const getfromdatevalue= e.target.value;
    const setfromformat= getfromdatevalue.split("-");
    const setfromyear= setfromformat[0];
    const setfrommonth= setfromformat[1];
    const setfromdate= setfromformat[2];
    const setfromformatdate= setfromyear+"-"+setfrommonth+"-"+setfromdate;   
    setFromdate(getfromdatevalue);
    setFromdateformat(setfromformatdate);
    //console.log(setfromformatdate);
 }
 const handleBox= (e)=>{
  const value = e.target.value;
  setState({...state, language: value });
}
 const handleApiRequest = (e) => {
  e.preventDefault();
   //const apiUrl = 'http://localhost:5128/api/Book/UserPerformanceLanguageWise';
    const apiUrl =  'https://www.softageteledocm.com/humapi/api/Book/UserPerformanceLanguageWise';
  const requestData = {
    createDateTo: todateformat,
    createDatefrom: fromdateformat,
    language: state.language,
  };
  axios.post(apiUrl, requestData, {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + userdata,
    },
  })
    .then((response) => {
      setState({...state, list: response.data})
      console.log(response.data)
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
}; 

const handleApiRequestCsv = (e) => {
  e.preventDefault();
   const apiUrl = 'https://www.softageteledocm.com/humapi/api/Book/UserPerformanceLanguageWiseCSV';
   //const apiUrl='http://localhost:5128/api/Book/UserPerformanceLanguageWiseCSV';
  const requestData = {
    createDateTo: todateformat,
    createDatefrom: fromdateformat,
    language: state.language,
  };
  axios.post(apiUrl, requestData, {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + userdata,
    },
  })
    .then((response) => {  
    // console.log(response.data.message);
    window.open(response.data.message);
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
}; 

console.log(state)
  return (
    <>
      <Container fluid>
        <Row className='d-flex justify-content-between'>
          <TopNavbar customJSX={<Button variant='primary' onClick={() => setShow(!show)}></Button>} />
          <Col  className="p-0" style={{ "max-width": "250px" }}>
            <SideMenu show={show} hide={handleClose}></SideMenu>
          </Col>
          <Col>      
          <div className='container-fluid UserReport_class'> 
              <h1 className='my-3'>Select a Date Range And Language </h1>
                <div className='row mb-4 justify-content-between align-items-center'>
                 
                  <div className='col-sm-3 d-flex align-items-center'>
                  <label className='whitespace-nowrap'>To Date:</label>
                    <input type='date' className='form-control mx-3' name='todate' placeholder='dd-mm-yyyy' onChange={(e) => handletodate(e)} />
                    <span className='text-danger'> </span>
                  </div>
                  
                  <div className='col-sm-3 d-flex align-items-center'>
                  <label className='whitespace-nowrap'>From Date:</label>
                    <input type='date' className='form-control mx-3' name='fromdate' placeholder='dd-mm-yyyy' disabled={disable} onChange={(e) => handlefromdate(e)} />
                  </div>
                <div className='col-sm-3 d-flex align-items-center'>
                 <label className='whitespace-nowrap'>Language :</label>
                 <select className='form-select mx-3' name='Language' disabled={disable} onChange={(e) => handleBox(e)}>
                 <option value=''>Select Language</option>
                 <option value='Hindi'>Hindi</option>
                 <option value='English'>English</option>
                 <option value='Urdu'>Urdu</option>
                 </select></div>
                  <div className='col-sm-3'>
                  <button className='btn btn-success mx-2' type='submit' onClick={handleApiRequest}>Submit</button>
                    <button className='btn btn-primary' type='submit' onClick={handleApiRequestCsv}>Download</button>
                  </div>
                </div>
              <div class="row table-content bg-white">
              <table id="myTable" className="text-left bg-white">
                <thead className="p-2">
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>User ID</th>
                    <th>Language</th>
                    <th>TotalWorkingHours</th>
                  </tr>
                </thead>
                <tbody>
                  {state.list.map((item, index) => (
                    <tr key={item.id}>
                      <td className="table_data">{index + 1}</td>
                      <td className="table_data">{item.userName}</td>
                      <td className="table_data">{item.userId}</td>
                      <td className="table_data">{item.language}</td>
                      <td className="table_data">{item.totalLengthInTime}</td>
                      <td className="table_data">
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UserReports;