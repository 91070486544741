import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from './Navbar';
import SideMenu from './SideMenu';
import '../component/Uploadprompt.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadPromptData = () => {
  const [bookName, setBookName] = useState('');
  const [language, setLanguage] = useState('English');
  const [images, setImages] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [notepadFile, setNotepadFile] = useState([]);

  const handleImageChange = (e) => {
    const newImages = Array.from(e.target.files);
    console.log(newImages)
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const uploadImages = async () => {
    if (images.length === 0) {
      alert('Please select images to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('BookName', bookName);
    formData.append('Language', language);

    images.forEach((image) => {
      formData.append('files', image);
    });
    try {
      const data1 = localStorage.getItem("response");
      const parsedData = JSON.parse(data1); // Parse the JSON string
      var userdata=parsedData.data.data.token;
      const response = await axios.post("https://www.softageteledocm.com/humapi/api/Book/BookDataUpload", formData, {
      // const response = await axios.post('http://localhost:5128/api/Book/BookDataUpload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + userdata
        }
      });
      setImages([]);
      setImagesUploaded(true);
      toast.success(response?.data?.bookUploadData?.fileUploadMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setImages([]);
      // setImagesUploaded(true);
      // alert('Images uploaded successfully!');
    } catch (error) {
      // console.error('Error uploading images:', error);
      alert('Failed to upload images.');
    }
  };

  const handleTextChange = (e) => {
    const text = Array.from(e.target.files);
    setNotepadFile((notepadFile) => [...notepadFile, ...text]);
  };
  const uploadTextFiles = async () => {
    if (notepadFile.length === 0) {
      alert('Please select currect  Notepad file to upload.');
      return;
    }
    const formData = new FormData();
    formData.append('BookName', bookName);
    formData.append('Language', language);
    // Assuming `notepadFile` is an array of files
    notepadFile.forEach((file) => {
      formData.append('files', file); // Use the correct key 'files'
    });
    try {    
      const data1 = localStorage.getItem("response");
      const parsedData = JSON.parse(data1); // Parse the JSON string
      var userdata=parsedData.data.data.token;
      const response = await axios.post('https://www.softageteledocm.com/humapi/api/Book/BookNotepad', formData, {
      // const response = await axios.post('http://localhost:5128/api/Book/BookNotepad', formData, {
        headers: {
          Authorization: "Bearer " + userdata
        }
      });
        setNotepadFile([]);
        toast.success(response?.data?.bookUploadData?.fileUploadMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // alert('Notepad uploaded successfully!');
    } catch (error) {
      // toast.success(error.response?.data?.bookUploadData?.fileUploadMessage, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    //   console.error('Error uploading Notepad file:', error.response?.data || error.message);
      alert('Failed to upload Notepad file.');
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <Container fluid>
        <Row className='d-flex justify-content-between'>
          <TopNavbar customJSX={<Button variant="primary" onClick={() => setShow(!show)}>Launch</Button>} />
          <Col md={2} className='p-0'>
            <SideMenu show={show} hide={handleClose}></SideMenu>
          </Col>
          <Col md={10}>
            <div className="row content">
              <div className="container">
                <div className="options-container">
                  <div className="form-group">
                    <label>Enter Book Name:</label>
                    <input
                      type="text"
                      value={bookName}
                      onChange={(e) => setBookName(e.target.value)}
                      placeholder="Book Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Select Language:</label>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                <option value="Assamese">Assamese</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Urdu">Urdu</option>
                <option value="Gujarati">Gujarati</option>
                <option value="Punjabi">Punjabi</option>
                <option value="Bengali">Bengali</option>
                <option value="Odia">Odia</option>
                <option value="Sanskrit">Sanskrit</option>
                <option value="Tamil">Tamil</option>
                <option value="Marathi">Marathi</option>
                <option value="Nepali">Nepali</option>   
                <option value="Bodo">Bodo</option>
                <option value="Kashmiri">Kashmiri</option>
                <option value="Maithili">Maithili</option>
                <option value="Manipuri">Manipuri</option>
                <option value="Santali">Santali</option>
                <option value="Telugu">Telugu</option>
               <option value="Dogri">Dogri</option>                
                <option value="Kannada">Kannada</option>
                <option value="Konkani">Konkani</option>
                <option value="Malayalam">Malayalam</option>
                    </select>
                  </div>
                </div>
                <div className="container1">
                  <div className="form-group">
                    <label>Select Images:</label>
                    <input type="file" multiple accept="image/*" onChange={handleImageChange} />
                    <button onClick={uploadImages}>Upload Images</button>
                  </div>
                  <div className="images-preview">
                    {images.map((image, index) => (
                      <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                    ))}
                  </div>
                </div>
                <div className="container2">
                  <div className="form-group">
                    <label>Select Notepad Files:</label>
                    <input type="file" multiple accept=".txt" onChange={handleTextChange}  />
                    <button onClick={uploadTextFiles} >
                      Upload Notepad Files
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default UploadPromptData;
