import './App.css';
import Login from './Components/Login';
import {Routes, Route} from 'react-router-dom';
import HomePage from './Components/Home/component/HomePage';
import FinancialReports from './Components/Home/component/FinancialReports';
import UserManagement from './Components/Home/component/UserManagement';
import UserAnalytics from './Components/Home/component/UserAnalytics';
import UploadPromptData from './Components/Home/component/UploadPromptData';
import ProductivityAndPerformance from './Components/Home/component/ProductivityAndPerformance';
import Logout from './Components/Home/component/Logout';
import SendNotification from './Components/Home/component/SendNotification';
import UserReports from './Components/Home/component/UserReports';
import ShowBookPrompt  from './Components/Home/component/ShowBookPrompt';
import Demo from './Components/Home/component/Demo';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
// import Home from './Components/Home/Home';
function App() {
  return (
    <div className="App">
      <Routes >     
            <Route path="/" exact element={<Login />} />
            <Route path="/homePage"  exact element={<HomePage />} /> 
            <Route path="/FinancialReports" exact element={<FinancialReports />} /> 
            <Route path="/UserManagement" exact element={<UserManagement/>} /> 
            <Route path="/UserAnalytics" exact element={<UserAnalytics/>} /> 
            <Route path="/UploadPromptData" exact element={<UploadPromptData/>} /> 
            <Route path="/ProductivityAndPerformance" exact element={<ProductivityAndPerformance/>} /> 
            <Route path="/SendNotification" exact element={<SendNotification/>} /> 
            <Route path="/UserReports" exact element={<UserReports/>} /> 
            <Route path="/ShowBookPrompt" exact element={<ShowBookPrompt/>} /> 
            <Route path="/Logout" exact element={<Logout/>}/>   
            {/* <Route path="/home" element={<Home />} />  */}     
             <Route path="/demo" element={<Demo />} />                
          </Routes>
    </div>
  );
}
export default App;
