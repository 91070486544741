import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from './Navbar';
import SideMenu from './SideMenu';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem('response');
    navigate("/");
  }
  return (
    <>
    <Container fluid>
    <Row className='d-flex justify-content-between'>
     <TopNavbar customJSX={  <Button variant="primary" onClick={() => setShow(!show)}>
        Launch
      </Button>} />
      <Col md={3} className='p-0'>
<SideMenu show={show} hide={handleClose}></SideMenu>
      </Col>
      <Col md={9}>
      <div class="row content">
    <div class="col-sm-3 sidenav hidden-xs">
    <div>
      {/* Your component content */}
      <button onClick={handleLogOut}>Logout</button>
    </div>
    </div>
    <div class="col-sm-9">
    </div>
    </div>
      </Col>
      
    </Row>
    </Container>
    </>
  );
}
export default Logout