import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { TopNavbar } from "./Navbar";
import SideMenu from "./SideMenu";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SendNotification() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({ title: "", body: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  console.log(formData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title: formData.title,
      body: formData.body,
    };
    try {
     await axios.post("https://www.softageteledocm.com/humapi/api/FCM/FcmMessage",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        if(res?.data?.status === 200){
           toast.success(res?.data?.message);
           setFormData({ title: "", body: "" });
        } else {
          toast.error(res?.data?.message ?? 'Internal server issue')
        }
      }).catch((err) => toast.error(JSON.stringify(err) ?? 'Internal server issue'))
    } catch (err) {
      setSuccessMessage("Failed to send notification.");
    }
  };

  return (
    <Container fluid>
      <Row className="d-flex justify-content-between">
        <TopNavbar
          customJSX={
            <Button variant="primary" onClick={() => setShow(!show)}>
              {/* Launch */}
            </Button>
          }
        />
        <Col md={2} className="p-0">
          <SideMenu show={show} hide={handleClose} />
        </Col>
        <Col md={10}>
          <div className="container SendNotification_class">
            <form onSubmit={handleSubmit}>
              <div className="row content">
                <div className="col-sm-12 sidenav hidden-xs text">
                  <h1>Send Notification</h1>
                </div>
                <div className="col-sm-12">
                  <div className="input_container">
                    <label htmlFor="title_name">Title</label>
                    <input
                      type="text"
                      className="title_name"
                      name="title"
                      id="title_name"
                      value={formData.title}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input_container">
                    <label htmlFor="message_name">Message</label>
                    <textarea
                      type="text"
                      className="message_name"
                      name="body"
                      id="message_name"
                      cols="100"
                      rows="5"
                      value={formData.body}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <button type="submit" className="btn_1">
                    Submit
                  </button>
                  {successMessage && <p>{successMessage}</p>}
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default SendNotification;
