import React, { useState } from 'react';
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import Logo from "../../../Assets/softAgeLogo.png";

export const TopNavbar = ({ customJSX }) => {
  const [userName, setUserName] = useState('');
  const data1 = localStorage.getItem("response");
  const parsedData = JSON.parse(data1); // Parse the JSON string
  var userdata=parsedData.data.data.name;
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <img
          className="logo"
          src={Logo}
          alt="Logo"
          style={{ width: "60px", height: "60px" }}
        />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link> */}
            <Nav.Link href="#link">Welcome {userdata}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default TopNavbar;
