import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from './Navbar';
import SideMenu from './SideMenu';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function FinancialReports() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const[responseData, setResponseData]=useState();
  const navigate = useNavigate();

  const handleLogin = async (event) => {
     try {
      const data1 = localStorage.getItem("response");
      const parsedData = JSON.parse(data1); // Parse the JSON string
      var userdata=parsedData.data.data.token;
       const response = await axios.post('https://www.softageteledocm.com/humapi/api/Update/EarningPage',
       {userId},{
        headers:{
        "Accept": "application/json",
        "Content-Type": "application/json",
         Authorization: "Bearer " + userdata}
       }).then(res=> setResponseData(res.data)).catch(err=> console.log(err));  
       // Handle the response as needed
        console.log('Login successful:', response.data.name);
        localStorage.setItem('response', JSON.stringify(response));
        if(response?.data?.error===false) 
        {
        //  window.alert("Userid and password sucess")
         navigate('/FinancialReports'); 
        }
        else
        {  
         window.alert("Invaild User")
        }
       // Optionally, you can redirect the user or perform other actions
        } catch (error) {
       // Handle errors, for example, show an error message to the user
         console.error('Login failed:', error.response);
        }
      // navigate('/home');
   };
  return (
    <>
    <Container fluid>
    <Row className='d-flex justify-content-between'>
     <TopNavbar customJSX={  <Button variant="primary" onClick={() => setShow(!show)}>
        {/* Launch */}
      </Button>} />
      <Col md={2} className='p-0'>
<SideMenu show={show} hide={handleClose}></SideMenu>
      </Col>
      <Col md={10}>
      <div className='container FinancialReports_class'>
      <form method='POST'>
      <div class="row content my-3">
      <div class="col-sm-12 sidenav hidden-xs">
      <h3 >View Earning Page</h3>
      </div>
      <div class="row content">
      <div class="col-sm-12" >
      <Stack direction="horizontal" gap={3} style={{display: "flex", alignItems: "center",marginTop:"11px"}}>
      <div>
      <Form.Label >Enter User Id:</Form.Label>
      <Form.Control
      type="text"
      aria-describedby="passwordHelpBlock"
      onChange={(e)=>setUserId(e.target.value)}
      />
      </div>
      <Button variant="primary" style={{marginTop: "31px"}} onClick={()=>handleLogin()}>Search</Button>
      </Stack>          
      </div>
      </div>
      </div>
     </form>
     </div>
     <Row className='my-3'>
      <Col md={12} >
        <div className='earning-box-container'>
        <div className='earning-today-container'>
          <h4 className='mb-3'>Today's Earnings</h4>
          <div className='earning-box'>
            <p>₹ {responseData?.data?.todayEarning}</p>
          </div>
        </div>
        <div className='earning-total-container'>
          <h4 className='mb-3'>Total Earnings</h4>
          <div className='earning-box'>
            <p>₹ {responseData?.data?.totalEarning}</p>
          </div>
        </div>
        </div>
      </Col>
      {/* <Col md={6}>
       
      </Col> */}
    </Row>
    </Col>
    </Row>
    </Container>
    </>
  );
}

export default FinancialReports