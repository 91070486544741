import {
  AppstoreOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SideMenu(props) {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");
  const [userType, setUserType] = useState('');
  const data1 = localStorage.getItem("response");
  const parsedData = JSON.parse(data1);
  const UserType = parsedData?.data?.data?.userType;

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem('response');
    navigate("/");
  }

  const commonMenuItems = [
    {
      label: "Home",
      key: "/homePage",
    },
  ];

  const adminMenuItems = [
    ...commonMenuItems,
    {
      label: "UserAnalytics",
      key: "/UserAnalytics",
    },
    {
      label: "FinancialReports",
      key: "/FinancialReports",
    },
    {
      label: "UserManagement",
      key: "/UserManagement",
    },
    {
      label: "UploadPromptData",
      key: "/UploadPromptData",
    },
    {
      label: "ProductivityReports",
      key: "/ProductivityAndPerformance",
    },
    {
      label: "SendNotification",
      key: "/SendNotification",
    },
    {
      label: "UserReports",
      key: "/UserReports",
    },
    {
      label: "Logout",
      key: '/logout'
    },
  ];

  const recorderMenuItems = [
    ...commonMenuItems,
    {
      label: "ShowBookPrompt",
      key: "/ShowBookPrompt",
    },
    {
      label: "Logout",
      key: "/logout",
    },
  ];

  const menuItems =
    UserType === "Admin" ? adminMenuItems : UserType === "Recorder" ? recorderMenuItems : commonMenuItems;

  // Check if localStorage data is empty and redirect to login page if so
  if (!parsedData) {
    navigate("/");
    return null; // Returning null to prevent rendering any content if redirected
  }

  return (
    <div className="SideMenu">
      <Menu
        className="SideMenuVertical"
        mode="vertical"
        onClick={(item) => {
          if (item.key === '/logout') {
            handleLogOut();
          } else {
            navigate(item.key); 
          }
        }}
        selectedKeys={[selectedKeys]}
        items={menuItems.map((item) => ({
          ...item,
          icon:
            item.label === "Home"
              ? <AppstoreOutlined />
              : item.label === "FinancialReports"
              ? <ShopOutlined />
              : item.label === "UserAnalytics"
              ? <ShoppingCartOutlined />
              : <UserOutlined />,
        }))}
      ></Menu>
    </div>
  );
}
export default SideMenu;
