import React, { useState } from 'react';
import Logo from "../Assets/softAgeLogo.png";
import image2 from "../Assets/App1.jpeg";
import image4 from "../Assets/App2.jpeg";
import icon from "../Assets/google-play.png";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Login() {
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://www.softageteledocm.com/humapi/api/Registration/Login', {
        userID,
        password,
        DeviceName: "Web",
        deviceid: "",
        Fcmtoken: ""
      });
      console.log('Login successful:', response.data.name);
      localStorage.setItem('response', JSON.stringify(response));

      if (response?.data?.error === false) {
        navigate('/HomePage');
      } else {
        toast.success("Invalid User", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error('Login failed:', error.response);
    }
  };

  return (
    <div className="maincontainer sm">
      <div className="left">
        <div className="logo">
          <img src={Logo} height="160px" width="160px" alt="logo" />
        </div>
        <div className="heading">
          <h6 className="welcome">WELCOME TO HUM APP PLEASE LOGIN TO PROCEED</h6>
          <h2>Sign In</h2>
        </div>

        <form className="card col-8 offset-2 mt-3 shadow-lg" onSubmit={handleLogin}>
          <div className="form-group col-10 offset-1 mb-5 center-input">
            <input 
              type="email" 
              id="username" 
              className="form-control" 
              placeholder=" " 
              value={userID}
              onChange={(e) => setUserID(e.target.value)} 
            />
            <span className="floating-placeholder">Username</span>
          </div>

          <div className="form-group col-10 offset-1 mt-2 center-input">
            <input 
              type="password" 
              id="password" 
              name="password" 
              className="form-control" 
              placeholder=" " 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="floating-placeholder">Password</span>
          </div>

          <div className="text-center pt-1 pb-1 col-10 offset-1">
            <button
              className="btn btn-primary btn-block fa-lg gradient-custom-2 "
              type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
      <div className="right">
        <div className="top">
          <div>
            <img className="image2" src={image2} height="40%" width="35%" alt="App1" />
          </div>

          <div>
            <img className="image4" src={image4} height="35%" width="30%" alt="App2" />
          </div>
        </div>

        <div className="bottom">
          <div className="play">
            <a href="https://play.google.com/store/apps/details?id=com.softage.hum&pcampaignid=web_share">
              <div className="icon">
                <div>
                  <img src={icon} height="60" width="60" alt="play-store" />
                </div>
                <div className="play-heading">
                  <h6>GET IT ON</h6>
                  <p>Google Play</p>
                </div>
              </div>
            </a>
          </div>

          {/* <div className="rbheading">
            <h1>WELCOME TO HUM WEB</h1> 
             <h4>Accelerate B2B sales & Business</h4> 
            <h4>distribution Grow Your</h4>
            <h4>Business</h4> 
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
