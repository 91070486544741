import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from '../component/Navbar';
import SideMenu from '../component/SideMenu';

const HomePage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
    <Container fluid>
    <Row className='d-flex justify-content-between'>
     <TopNavbar customJSX={  <Button variant="primary" onClick={() => setShow(!show)}>
        {/* Launch */}
      </Button>} />
      <Col md={2} className='p-0'>
      {/* <Sidebar show={show} hide={handleClose} /> */}
      <SideMenu show={show} hide={handleClose}></SideMenu>
      </Col>
      <Col md={10}>
      <div class="row content">
      <div class="col-sm-3 sidenav hidden-xs">
                                                Welcome Softage 
      {/* <img className='img-fluid showbookimg' src="https://www.softageteledocm.com/humapi/BooksData/silent-partners-hugh-perry-obooko_page-0001.jpg" alt="test" /> */}
       </div>
      {/* <div class="col-sm-9">
    </div> */}
    </div>
      </Col>
    </Row>
    </Container>
    </>
  );
}

export default HomePage