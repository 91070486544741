import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TopNavbar } from './Navbar';
import SideMenu from './SideMenu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ShowBookPrompt() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [responseData, setResponseData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const data1 = localStorage.getItem("response");
    if (data1) {
      fetchBook()
      
    } else {
      navigate('/login');
    }
  }, []);
  const fetchBookNext= () => {

    toast.success("Are You Sure Complete Recording then click next Page!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const data1 = localStorage.getItem("response");
    const parsedData = JSON.parse(data1);
      var userdata = parsedData.data.data.token;
      var userId = parsedData.data.data.userID;
      axios.post('https://www.softageteledocm.com/humapi/api/Book/BookView',
      { userId }, 
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata
        }
      }
    )
    .then(res => {
      setResponseData(res.data.bookData.bookPathSftp
        );
    })
    
    .catch(err => {
      console.log(err);
    });
  }
  const fetchBook= () => {
    const data1 = localStorage.getItem("response");
    const parsedData = JSON.parse(data1);
      var userdata = parsedData.data.data.token;
      var userId = parsedData.data.data.userID;
      axios.post('https://www.softageteledocm.com/humapi/api/Book/BookView',
      { userId }, 
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata
        }
      }
    )
    .then(res => {
      setResponseData(res.data.bookData.bookPathSftp
        );
    })
    
    .catch(err => {
      console.log(err);
    });
  }
  return (
    <>
      <Container fluid>
        <Row className='d-flex justify-content-between'>
          <TopNavbar customJSX={<Button variant="primary" onClick={() => setShow(!show)}>
            {/* Launch */}
          </Button>} />
          <Col md={3} className='p-0'>
            <SideMenu show={show} hide={handleClose}></SideMenu>
          </Col>
          <Col md={9}>
          <div className='p-2'>
          <button className='btn btn-primary float-end'  onClick={fetchBookNext}>Next</button>
          </div>
            <div className="image_content">
                <img className='img-fluid ' src={responseData} alt="test" />
              </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}
export default ShowBookPrompt;
