import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { TopNavbar } from "./Navbar";
import SideMenu from "./SideMenu";

const UserAnalytics = () => {
  const [show, setShow] = useState(false);
  const [isChecked, setChecked] = useState(true);
  const [state, setState] = useState([]);
  const handleClose = () => setShow(false);

  const data1 = localStorage.getItem("response");
  const parsedData = JSON.parse(data1); // Parse the JSON string
  var userdata = parsedData.data.data.token;
  var userId = parsedData.data.data.userID;
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + userdata,
  };
  const fetch = async (event) => {
    await axios
      .post("https://www.softageteledocm.com/humapi/api/Update/UserShowData",
        { userId },
        {
          headers: header,
        }
      )
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          setState(res?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleCheckboxChange = async(index, user) => {
    const updatedState = [...state]; // Create a copy of the state array
    updatedState[index].isActive = updatedState[index].isActive === 1 ? 0 : 1; // Toggle isActive value
    setState(updatedState);

    await axios
    .post("https://www.softageteledocm.com/humapi/api/Update/UserActiveDeactive",
      {"userId": user, 'setActive': Boolean(updatedState[index].isActive) },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res?.data?.data?.length > 0) {
        setState(res?.data?.data);
      }
    })
    .catch((err) => console.log(err));
  };
  return (
    <>
      <Container fluid>
        <Row className="d-flex justify-content-between">
          <TopNavbar
            customJSX={
              <Button variant="primary" onClick={() => setShow(!show)}>
                {/* Launch */}
              </Button>
            }
          />
          <Col className="p-0" style={{ "max-width": "250px" }}>
            <SideMenu show={show} hide={handleClose}></SideMenu>
          </Col>
          <Col>
            <div class="row table-content bg-white">
              <table id="myTable" className="text-left bg-white">
                <thead className="p-2">
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>User ID</th>
                    <th>Language</th>
                    <th>Voice Id</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Mobile</th>
                    <th>User Type</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((item, index) => (
                    <tr key={index}>
                      <td className="table_data">{index + 1}</td>
                      <td className="table_data">{item.name}</td>
                      <td className="table_data">{item.userId}</td>
                      <td className="table_data">{item.language}</td>
                      <td className="table_data">{item.voiceid}</td>
                      <td className="table_data">{item.age}</td>
                      <td className="table_data">{item.gender}</td>
                      <td className="table_data">{item.mobile}</td>
                      <td className="table_data">{item.userType}</td>
                      <td className="table_data">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={item.isActive === 1 ? true : false}
                            onChange={() => handleCheckboxChange(index, item.userId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UserAnalytics;
